.entry-content > .wp-block-cta-gutenberg-cta:not(.alignwide):not(.alignfull):not(.alignleft):not(.alignright):not(.is-style-wide){
    width: 100%;
    max-width: 1170px;
}
.wp-block-wppool-cta{
    max-width: 1170px !important;
}
/* *********************************************************************************** */
/* degine one */
/* *********************************************************************************** */
.call_to_action_1 {
    position: relative;
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    grid-gap: 90px;
    padding: 80px;
    background-repeat: no-repeat;
    background-position: 90% 80%;
    font-family: 'Lato', sans-serif;
    max-width: 1170px;
    margin: auto;
    box-sizing: border-box;
    &:after, &:before {
        position: absolute;
        content: '';
        height: 100%;
        width: 100%;
        -webkit-transform: skewX(-9deg);
        transform: skewX(-9deg);
        background-color: transparent;
    }
    &:before {
        border: 1px solid #000;
        top: -10px;
        left: 10px;
        z-index: -1;
    }
    &:after {
        background-color: #7840FF;
        top: 0;
        left: 0;
        z-index: -2;
    }
    &>.cta_content {
        z-index: 1;
        color: #fff;
        padding-left: 70px;
        box-sizing: border-box;
        -ms-flex-item-align: center;
        align-self: center;
        h2{
            font-family: inherit;
            font-size: 30px;
            font-weight: 700;
            margin: 25px auto 25px;
            line-height: 36px;
        }
        p{
            font-family: inherit;
            font-weight: 300;
            font-size: 16px;
            line-height: 26px;
            margin-top: 16px;
            margin-bottom: 30px;
        }
        > button,
        > .ctagb-btn {
            background: #fff;
            font-size: 16px;
            text-transform: uppercase;
            padding: 15px 30px;
            cursor: pointer;
            position: relative;
            padding-right: 60px;
            border: 1px solid #fff;
            color: black;
            transition: all 300ms ease;
            font-family: inherit;
            font-weight: 400;
            text-decoration: none;
            display: inline-block;
            &:focus {
                outline: none;
            }
            &:after {
                position: absolute;
                content: '\2192';
                font-size: 24px;
                top: calc(50% - 3px);
                line-height: 0;
                transform: translateY(-50%);
                right: 27px;
                transition: all 300ms ease;
            }
            &:hover{
                background: #7840FF;
                color: #fff;
                text-decoration: none;
                &:after {
                    right: 22px;
                    color: #fff;
                }
            }
        }
    }
}

/* ********************************************************************** */
/* design two */
/* ********************************************************************** */
.call_to_action_2,
.call_to_action_3,
.call_to_action_12{
    &.alignfull {
        background-position: 30% center;
        padding: 100px 20%;
        max-width: inherit;
        width: 100% !important;
    }
}

.call_to_action_2 {
    position: relative;
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    background-color: #7840FF;
    background-repeat: no-repeat;
    font-family: 'Lato', sans-serif;
    margin: auto;
    box-sizing: border-box;
    background-position: 10% center;
    max-width: 1170px;
    padding: 80px;
    &>.cta_content {
        z-index: 1;
        color: #fff;
        box-sizing: border-box;
        grid-column: 2/3;
        grid-row: 1/2;
        padding-right: 120px;
        -ms-flex-item-align: center;
        align-self: center;
        h2{
            font-family: inherit;
            font-size: 30px;
            font-weight: 700;
            margin: 25px auto 25px;
            line-height: 36px;
        }
        p{
            font-family: inherit;
            font-weight: 300;
            font-size: 16px;
            line-height: 26px;
            margin-top: 16px;
            margin-bottom: 30px;
        }
        >button,
        > .ctagb-btn {
            background: #FF8C40;
            font-size: 16px;
            text-transform: uppercase;
            padding: 15px 30px;
            border: 1px solid #FF8C40;
            cursor: pointer;
            position: relative;
            padding-right: 60px;
            color: #fff;
            transition: all 300ms ease;
            font-family: inherit;
            font-weight: 400;
            text-decoration: none;
            display: inline-block;
            &:after {
                position: absolute;
                content: '\2192';
                font-size: 24px;
                top: calc(50% - 3px);
                line-height: 0;
                transform: translateY(-50%);
                right: 27px;
                transition: all 300ms ease;
            }
            &:focus {
                outline: none;
            }
            &:hover {
                background-color: #fff;
                color: #FF8C40;
                &:after {
                    right: 22px;
                    color: #FF8C40;
                }
            }
        }
    }
}

/* *************************************************************************** */
/* design three */
/* *************************************************************************** */
.call_to_action_3 {
    position: relative;
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    background-color: #7840FF;
    padding: 80px;
    background-repeat: no-repeat;
    background-position: 10% center;
    font-family: 'Lato', sans-serif;
    margin: auto;
    box-sizing: border-box;
    max-width: 1170px;
    >.cta_content {
        z-index: 1;
        color: #fff;
        box-sizing: border-box;
        grid-column: 2/3;
        grid-row: 1/2;
        padding-right: 120px;
        -ms-flex-item-align: center;
        align-self: center;
        h2{
            font-family: inherit;
            font-size: 30px;
            font-weight: 700;
            margin: 25px auto 25px;
            line-height: 36px;
        }
        p{
            font-family: inherit;
            font-weight: 300;
            font-size: 16px;
            line-height: 26px;
            margin-top: 16px;
            margin-bottom: 30px;
        }
        > button,
        > .ctagb-btn {
            background: #fff;
            border: 1px solid #fff;
            font-size: 16px;
            text-transform: uppercase;
            padding: 18px 30px;
            border-radius: 50px;
            cursor: pointer;
            color: #FF8C40;
            transition: all 300ms ease;
            font-family: inherit;
            font-weight: 400;
            text-decoration: none;
            display: inline-block;
            &:focus {
                outline: none;
            }
            &:hover {
                background: #FF8C40;
                color: #fff;
            }
        }
    }
}

/* ********************************************************************************* */
/* design four */
/* ********************************************************************************* */

.call_to_action_4, 
.call_to_action_5, 
.call_to_action_6, 
.call_to_action_7 {
    &.alignfull {
        border-radius: 0;
        max-width: inherit;
        padding: 100px 20%;
    }
}

.call_to_action_4 {
    position: relative;
    background-color: #ffffff;
    padding: 80px 100px;
    font-family: 'Lato', sans-serif;
    max-width: 1170px;
    margin: auto;
    box-sizing: border-box;
    border-radius: 20px;
    box-shadow: 0px 0px 60px -12px rgba(34, 34, 34, 0.2);
    background-repeat: no-repeat;
    background-position: 90% 80%;
    >.cta_content {
        z-index: 1;
        box-sizing: border-box;
        display: -ms-grid;
        display: grid;
        -ms-grid-columns: 3fr 1fr;
        grid-template-columns: 3fr 1fr;
        position: relative;
        >h2 {
            font-size: 24px;
            color: #222222;
            font-family: inherit;
            font-weight: 700;
            margin: 20px 0 20px;
            line-height: 29px;
        }
        >p {
            color: #444444;
            grid-row: 2/3;
            grid-column: 1/2;
            padding-right: 120px;
            margin: 0;
            font-family: inherit;
            font-weight: 300;
            font-size: 16px;
            line-height: 26px;
        }
        >button,
        > .ctagb-btn {
            background: #FF8C40;
            border: 1px solid #FF8C40;
            font-size: 16px;
            text-transform: capitalize;
            cursor: pointer;
            color: #fff;
            border-radius: 5px;
            position: absolute;
            right: 0;
            top: 50%;
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
            transition: all 300ms ease;
            font-family: inherit;
            font-weight: 400;
            text-decoration: none;
            display: inline-block;
            padding: 17px 30px;            
            padding-right: 60px;
            &:after {
                position: absolute;
                content: '\2192';
                font-size: 24px;
                transition: all 300ms ease;
                top: calc(50% - 3px);
                line-height: 0;
                transform: translateY(-50%);
                right: 27px;
            }
            &:focus {
                outline: none;
            }
            &:hover {
                color: #FF8C40;
                background: #fff;
                &:after {
                    right: 22px;
                    color: #FF8C40;
                }
            }
        }
    }
}

/* ********************************************************************* */
/* design five */
/* ********************************************************************* */
.call_to_action_5 {
    position: relative;
    background-color: #ffffff;
    padding: 80px 100px;
    font-family: 'Lato', sans-serif;
    max-width: 1170px;
    margin: auto;
    box-sizing: border-box;
    border-radius: 20px;
    box-shadow: 0px 0px 60px -12px rgba(34, 34, 34, 0.2);
    background-repeat: no-repeat;
    background-position: 90% 80%;
    >.cta_content {
        z-index: 1;
        box-sizing: border-box;
        display: -ms-grid;
        display: grid;
        -ms-grid-columns: 3fr 1fr;
        grid-template-columns: 3fr 1fr;
        position: relative;
        >h2 {
            font-size: 24px;
            color: #222222;
            font-family: inherit;
            font-weight: 700;
            margin: 20px 0 20px;
            line-height: 29px;
        }
        >p {
            color: #444444;
            grid-row: 2/3;
            grid-column: 1/2;
            padding-right: 120px;
            margin: 0;
            font-family: inherit;
            font-weight: 300;
            font-size: 16px;
            line-height: 26px;
        }
        >button,
        > .ctagb-btn {
            background: #1C94FC;
            border: 1px solid #1C94FC;
            font-size: 16px;
            text-transform: capitalize;
            color: #fff;
            border-radius: 5px;
            position: absolute;
            cursor: pointer;
            right: 0;
            top: 50%;
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
            transition: all 300ms ease;
            font-family: inherit;
            font-weight: 400;
            text-decoration: none;
            display: inline-block;
            padding: 17px 30px;
            padding-right: 60px;
            &:after {
                position: absolute;
                content: '\2192';
                font-size: 24px;
                right: 27px;
                transition: all 300ms ease;
                top: calc(50% - 3px);
                line-height: 0;
                transform: translateY(-50%);
            }
            &:focus {
                outline: none;
            }
            &:hover {
                color: #1C94FC;
                background: #fff;
                &:after {
                    right: 22px;
                    color: #1c94fc;
                }
            }
        }
    }
}

/* ************************************************************** */
/* design Six */
/* ************************************************************** */
.call_to_action_6 {
    position: relative;
    background-color: #1C94FC;
    padding: 80px 100px;
    font-family: 'Lato', sans-serif;
    max-width: 1170px;
    margin: auto;
    box-sizing: border-box;
    border-radius: 20px;
    box-shadow: 0px 0px 60px -12px rgba(28, 148, 252, 0.4);
    color: #fff;
    background-repeat: no-repeat;
    background-position: 90% 80%;
    >.cta_content {
        z-index: 1;
        box-sizing: border-box;
        display: -ms-grid;
        display: grid;
        -ms-grid-columns: 3fr 1fr;
        grid-template-columns: 3fr 1fr;
        position: relative;
        >h2 {
            font-size: 24px;
            font-family: inherit;
            font-weight: 700;
            margin: 20px 0 20px;
            line-height: 29px;
        }
        >p {
            grid-row: 2/3;
            grid-column: 1/2;
            padding-right: 120px;
            margin: 0;
            font-family: inherit;
            font-weight: 300;
            font-size: 16px;
            line-height: 26px;
        }
        >button,
        > .ctagb-btn {
            background: #ffffff;
            border: 1px solid #ffffff;
            font-size: 16px;
            text-transform: capitalize;
            padding: 20px 30px;
            padding-right: 60px;
            color: #1C94FC;
            border-radius: 5px;
            position: absolute;
            cursor: pointer;
            right: 0;
            top: 50%;
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
            transition: all 300ms ease;
            font-family: inherit;
            font-weight: 400;
            text-decoration: none;
            display: inline-block;
            padding: 17px 30px;
            padding-right: 60px;
            &:after {
                position: absolute;
                content: '\2192';
                font-size: 24px;
                right: 27px;
                transition: all 300ms ease;
                top: calc(50% - 3px);
                line-height: 0;
                transform: translateY(-50%);
            }
            &:focus {
                outline: none;
            }
            &:hover {
                color: #ffffff;
                background: #1C94FC;
                &:after {
                    right: 22px;
                    color: #ffffff;
                }
            }
        }        
    }
}

/* ***************************************************************** */
/* design seven */
/* ***************************************************************** */
.call_to_action_7 {
    position: relative;
    background-color: #ffffff;
    padding: 80px 100px;
    font-family: 'Lato', sans-serif;
    max-width: 1170px;
    margin: auto;
    box-sizing: border-box;
    border-radius: 20px;
    box-shadow: 0px 0px 60px -12px rgba(34, 34, 34, 0.2);
    background-repeat: no-repeat;
    background-position: 90% 80%;
    >.cta_content {
        z-index: 1;
        box-sizing: border-box;
        display: -ms-grid;
        display: grid;
        -ms-grid-columns: 3fr 1fr;
        grid-template-columns: 3fr 1fr;
        position: relative;
        >h2 {
            font-size: 24px;
            color: #222222;
            font-family: inherit;
            font-weight: 700;
            margin: 20px 0 20px;
            line-height: 29px;
        }
        >p {
            color: #444444;
            grid-row: 2/3;
            grid-column: 1/2;
            padding-right: 120px;
            margin: 0;
            font-family: inherit;
            font-weight: 300;
            font-size: 16px;
            line-height: 26px;
        }
        >button,
        > .ctagb-btn {
            background: #002BFF;
            font-size: 16px;
            text-transform: capitalize;
            border: 1px solid #002bff;
            cursor: pointer;
            color: #fff;
            border-radius: 50px;
            position: absolute;
            right: 0;
            top: 50%;
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
            transition: all 300ms ease;
            font-family: inherit;
            font-weight: 400;
            text-decoration: none;
            display: inline-block;
            padding: 17px 30px;
            padding-right: 60px;
            &:after {
                position: absolute;
                content: '\2192';
                font-size: 24px;
                right: 27px;
                transition: all 300ms ease;
                top: calc(50% - 3px);
                line-height: 0;
                transform: translateY(-50%);
            }
            &:focus {
                outline: none;
            }
            &:hover {
                color: #002BFF;
                background: #fff;
                &:after {
                    right: 22px;
                    color: #002bff;
                }
            }
        }
    }
}

/* ******************************************************************* */
/* degine eight */
/* ****************************************************************** */
.call_to_action_8 {
    position: relative;
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    grid-gap: 200px;
    padding: 100px;
    background-repeat: no-repeat;
    background-position: 90% center;
    font-family: 'Lato', sans-serif;
    max-width: 1170px;
    margin: 60px auto;
    box-sizing: border-box;
    &.alignfull {
        background-position: 70% center;
        padding: 120px 20%;
        max-width: inherit;
        width: 100% !important;
        &:after {
            border-radius: 0;
        }
    }
    &:after {
        position: absolute;
        content: '';
        height: 100%;
        width: 100%;
        border-radius: 20px;
        background: -webkit-linear-gradient(left, #009BED, #0063F5);
        background: linear-gradient(to right, #009BED, #0063F5);
        top: 0;
        left: 0;
        z-index: -2;
    }
    >.cta_content {
        z-index: 1;
        color: #fff;
        box-sizing: border-box;
        -ms-flex-item-align: center;
        align-self: center;
        h2{
            font-family: inherit;
            font-size: 30px;
            font-weight: 700;
            margin: 25px auto 25px;
            line-height: 36px;
        }
        p{
            font-family: inherit;
            font-weight: 300;
            font-size: 16px;
            line-height: 26px;
            margin-top: 16px;
            margin-bottom: 30px;
        }
        >button,
        > .ctagb-btn {
            background: #FAC240;
            border: 1px solid #FAC240;
            font-size: 16px;
            text-transform: capitalize;
            padding: 15px 30px;
            padding-right: 60px;
            color: #222222;
            border-radius: 50px;
            cursor: pointer;
            position: relative;
            transition: all 300ms ease;
            font-family: inherit;
            font-weight: 400;
            text-decoration: none;
            display: inline-block;
            &:after {
                position: absolute;
                content: '\2192';
                font-size: 24px;
                top: calc(50% - 3px);
                line-height: 0;
                transform: translateY(-50%);
                right: 27px;
                transition: all 300ms ease;
            }
            &:focus {
                outline: none;
            }
            &:hover {
                background: -webkit-linear-gradient(right, #009BED, #0063F5);
                background: linear-gradient(to left, #009BED, #0063F5);
                color: #fff;
                border-color: #fff;
                &:after {
                    right: 22px;
                    color: #fff;
                }
            }
        }
    }
}

/* ********************************************************************* */
/* degine nine */
/* ********************************************************************* */
.call_to_action_9{
    position: relative;
    padding: 80px;
    font-family: 'Lato', sans-serif;
    max-width: 1170px;
    margin: auto;
    box-sizing: border-box;
    background-repeat: no-repeat;
    background-position: 90% center;
    &:after {
        position: absolute;
        content: '';
        height: 100%;
        width: 100%;
        border-radius: 20px;
        background: -webkit-linear-gradient(left, #DC2430, #7B4397);
        background: linear-gradient(to right, #DC2430, #7B4397);
        top: 0;
        left: 0;
        z-index: -2;
    }
    &.alignfull {
        max-width: inherit;
        padding: 100px 20%;
        &:after {
            border-radius: 0;
        }
    }
    >.cta_content {
        z-index: 1;
        color: #fff;
        box-sizing: border-box;
        -ms-flex-item-align: center;
        align-self: center;
        width: 50%;
        margin: 0 auto;
        text-align: center;
        h2{
            font-family: inherit;
            font-size: 30px;
            font-weight: 700;
            margin: 25px auto 25px;
            line-height: 36px;
        }
        p{
            font-family: inherit;
            font-weight: 300;
            font-size: 16px;
            line-height: 26px;
            margin-top: 16px;
            margin-bottom: 30px;
        }
        >button,
        > .ctagb-btn {
            background: #ffffff;
            border: 1px solid #fff;
            font-size: 16px;
            text-transform: capitalize;
            padding: 17px 30px;
            padding-right: 60px;
            color: #DC2430;
            border-radius: 50px;
            cursor: pointer;
            position: relative;
            margin-top: 30px;
            transition: all 300ms ease;
            font-family: inherit;
            font-weight: 400;
            text-decoration: none;
            display: inline-block;
            &:after {
                position: absolute;
                content: '\2192';
                font-size: 24px;
                top: calc(50% - 3px);
                line-height: 0;
                transform: translateY(-50%);
                right: 27px;
                transition: all 300ms ease;
            }
            &:focus {
                outline: none;
            }
            &:hover {
                background: -webkit-linear-gradient(right, #DC2430, #7B4397);
                background: linear-gradient(to left, #DC2430, #7B4397);
                color: #fff;
                &:after {
                    right: 22px;
                    color: #ffffff;
                }
            }
        }
    }
}

/* ************************************************************** */
/* design ten */
/* ************************************************************** */
.call_to_action_10{
    position: relative;
    background-color: #002BFF;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    padding: 80px 190px;
    font-family: 'Lato', sans-serif;
    max-width: 1170px;
    margin: auto;
    box-sizing: border-box;
    border-radius: 20px;
    box-shadow: 0px 0px 60px -12px rgba(0, 43, 255, 0.4);
    &.alignfull {
        max-width: inherit;
        padding: 120px 20%;
        border-radius: 0;
    }
    >.cta_content {
        z-index: 1;
        box-sizing: border-box;
        display: -ms-grid;
        display: grid;
        -ms-grid-columns: 2fr 1fr;
        grid-template-columns: 2fr 1fr;
        position: relative;
        color: #ffffff;
        >h2{
            font-family: inherit;
            font-size: 30px;
            font-weight: 700;
            margin: 25px 0 25px;
            line-height: 36px;
        }
        >p{
            font-family: inherit;
            font-weight: 300;
            font-size: 16px;
            line-height: 26px;
            grid-row: 2/3;
            grid-column: 1/2;
            padding-right: 120px;
            margin: 0;
        }
        >button,
        > .ctagb-btn {
            background: #ffffff;
            border: 1px solid #fff;
            font-size: 16px;
            text-transform: capitalize;
            padding: 17px 30px;
            cursor: pointer;
            padding-right: 60px;
            color: #222;
            border-radius: 50px;
            position: absolute;
            right: 0;
            top: 50%;
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
            transition: all 300ms ease;
            font-family: inherit;
            font-weight: 400;
            text-decoration: none;
            display: inline-block;
            &:after {
                position: absolute;
                content: '\2192';
                font-size: 24px;
                top: calc(50% - 3px);
                line-height: 0;
                transform: translateY(-50%);
                right: 27px;
                transition: all 300ms ease;
            }
            &:focus {
                outline: none;
            }
            &:hover {
                color: #fff;
                background-color: #002bff;
                &:after {
                    right: 22px;
                    color: #ffffff;
                }
            }
        }
    }
}

/* ********************************************************************************* */
/* design eleven */
/* ********************************************************************************* */
.call_to_action_11{
    position: relative;
    background-color: #053E73;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    padding: 80px 190px;
    font-family: 'Lato', sans-serif;
    max-width: 1170px;
    margin: 60px auto;
    box-sizing: border-box;
    box-shadow: 0px 0px 60px -12px rgba(5, 62, 115, 0.4);
    &.alignfull {
        max-width: inherit;
        padding: 120px 20%;
        border-radius: 0;
    }
    >.cta_content {
        z-index: 1;
        box-sizing: border-box;
        display: -ms-grid;
        display: grid;
        -ms-grid-columns: 2fr 1fr;
        grid-template-columns: 2fr 1fr;
        position: relative;
        color: #ffffff;
        >h2{
            font-family: inherit;
            font-size: 30px;
            font-weight: 700;
            margin: 25px 0 25px;
            line-height: 36px;
        }
        >p {
            font-family: inherit;
            font-weight: 300;
            font-size: 16px;
            line-height: 26px;
            grid-row: 2/3;
            grid-column: 1/2;
            padding-right: 120px;
            margin: 0;
        }
        >button,
        > .ctagb-btn {
            background: #E94D65;
            border: 1px solid #E94D65;
            font-size: 16px;
            text-transform: capitalize;
            padding: 17px 30px;
            cursor: pointer;
            padding-right: 60px;
            color: #ffffff;
            position: absolute;
            right: 0;
            top: 50%;
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
            transition: all 300ms ease;
            font-family: inherit;
            font-weight: 400;
            text-decoration: none;
            display: inline-block;
            &:after {
                position: absolute;
                content: '\2192';
                font-size: 24px;
                top: calc(50% - 3px);
                line-height: 0;
                transform: translateY(-50%);
                right: 27px;
                transition: all 300ms ease;
            }
            &:focus {
                outline: none;
            }
            &:hover {
                background: #c93c51;
                border: 1px solid #c93c51;
                &:after {
                    right: 22px;
                    color: #fff;
                }
            }
        }
    }
}

/* ********************************************************************************* */
/* design twelve */
/* ********************************************************************************* */
.call_to_action_12 {
    position: relative;
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 4fr 3fr;
    grid-template-columns: 4fr 3fr;
    background-color: #7840FF;
    background-repeat: no-repeat;
    font-family: 'Lato', sans-serif;
    margin: auto;
    box-sizing: border-box;
    background-position: 0 center;
    background-size: cover;
    max-width: 1170px;
    padding: 80px;
    &>.cta_content {
        z-index: 1;
        color: #fff;
        box-sizing: border-box;
        grid-column: 2/3;
        grid-row: 1/2;
        padding-right: 0;
        -ms-flex-item-align: center;
        align-self: center;
        h2{
            font-family: inherit;
            font-size: 30px;
            font-weight: 700;
            margin: 25px auto 25px;
            line-height: 36px;
        }
        p{
            font-family: inherit;
            font-weight: 300;
            font-size: 16px;
            line-height: 26px;
            margin-top: 16px;
            margin-bottom: 30px;
        }
        >button,
        > .ctagb-btn {
            background: #FF8C40;
            font-size: 16px;
            text-transform: uppercase;
            padding: 15px 30px;
            border: 1px solid #FF8C40;
            cursor: pointer;
            position: relative;
            padding-right: 60px;
            color: #fff;
            transition: all 300ms ease;
            font-family: inherit;
            font-weight: 400;
            text-decoration: none;
            display: inline-block;
            &:after {
                position: absolute;
                content: '\2192';
                font-size: 24px;
                top: calc(50% - 3px);
                line-height: 0;
                transform: translateY(-50%);
                right: 27px;
                transition: all 300ms ease;
            }
            &:focus {
                outline: none;
            }
            &:hover {
                background-color: #fff;
                color: #FF8C40;
                &:after {
                    right: 22px;
                    color: #FF8C40;
                }
            }
        }
    }
}

/* Large devices (desktops, 992px and up) */

@media (min-width: 992px) and (max-width: 1199.98px) {
    .wp-block-cta-gutenberg-cta{
        >.cta_content{
            >h2 {
                font-size: 24px;
            }
            >p {
                margin-bottom: 25px;
            }
        }
    }    
    .call_to_action_2,
    .call_to_action_3,
    .call_to_action_4,
    .call_to_action_5,
    .call_to_action_6,
    .call_to_action_7,
    .call_to_action_8,
    .call_to_action_9,
    .call_to_action_10,
    .call_to_action_11{
        &.alignfull {
            width: 100%;
            padding: 80px 10%;
            background-position: 10% center;
        }
    }
    .call_to_action_8.alignfull {
        background-position: 90% center;
    }
    .call_to_action_1, 
    .call_to_action_2, 
    .call_to_action_3, 
    .call_to_action_4, 
    .call_to_action_5, 
    .call_to_action_6, 
    .call_to_action_7, 
    .call_to_action_8, 
    .call_to_action_9, 
    .call_to_action_10, 
    .call_to_action_11 {
        width: 80%;
        padding: 40px;
    }
    .call_to_action_1 {
        grid-gap: 50px;
        background-position: 95% 84%;
        background-size: 40%;
        padding: 60px 40px;
    }
    .call_to_action_1>.cta_content {
        padding-left: 30px;
    }
    .call_to_action_3, .call_to_action_2 {
        background-position: 5% center;
        background-size: 40%;
    }
    .call_to_action_3>.cta_content, .call_to_action_2>.cta_content {
        padding-right: 0;
    }
    .call_to_action_4>.cta_content>h2, .call_to_action_6>.cta_content>h2, .call_to_action_7>.cta_content>h2, .call_to_action_5>.cta_content>h2 {
        font-size: 20px;
        padding-right: 50px;
    }
    .call_to_action_4>.cta_content>p, .call_to_action_6>.cta_content>p, .call_to_action_7>.cta_content>p, .call_to_action_5>.cta_content>p {
        padding-right: 80px;
    }
    .call_to_action_4>.cta_content>button, .call_to_action_6>.cta_content>button, .call_to_action_7>.cta_content>button, .call_to_action_5>.cta_content>button {
        font-size: 14px;
        padding: 20px;
        padding-right: 57px;
    }
    .call_to_action_8 {
        grid-gap: 50px;
        background-size: 50%;
    }
}

/* Medium devices (tablets, 768px and up) */

@media (min-width: 750px) and (max-width: 991.98px) {
    .wp-block-cta-gutenberg-cta{
        >.cta_content{
            >h2 {
                font-size: 24px;
            }
            >p {
                margin-bottom: 25px;
            }
        }
    } 
    .call_to_action_2, 
    .call_to_action_3, 
    .call_to_action_4, 
    .call_to_action_5, 
    .call_to_action_6, 
    .call_to_action_7, 
    .call_to_action_8, 
    .call_to_action_9, 
    .call_to_action_10, 
    .call_to_action_11{
        &.alignfull{
            width: 100%;
            padding: 80px 10%;
            background-position: 10% center;
        }
    }
    .call_to_action_8.alignfull {
        background-position: 90% center;
    }
    .call_to_action_1, .call_to_action_2, .call_to_action_3, .call_to_action_4, .call_to_action_5, .call_to_action_6, .call_to_action_7, .call_to_action_8, .call_to_action_9, .call_to_action_10, .call_to_action_11 {
        width: 80%;
        padding: 40px;
    }
    .call_to_action_1 {
        grid-gap: 50px;
        background-position: 95% 84%;
        background-size: 40%;
        padding: 60px 40px;
        &:before, &:after{
            transform: skewX(0deg);
        }
    }
    .call_to_action_1>.cta_content {
        padding-left: 30px;
    }
    .call_to_action_3, .call_to_action_2 {
        background-position: 5% center;
        background-size: 40%;
    }
    .call_to_action_3>.cta_content, .call_to_action_2>.cta_content {
        padding-right: 0;
    }
    .call_to_action_4>.cta_content>h2, .call_to_action_6>.cta_content>h2, .call_to_action_7>.cta_content>h2, .call_to_action_5>.cta_content>h2 {
        font-size: 20px;
        padding-right: 50px;
    }
    .call_to_action_4>.cta_content>p, .call_to_action_6>.cta_content>p, .call_to_action_7>.cta_content>p, .call_to_action_5>.cta_content>p {
        padding-right: 80px;
    }
    .call_to_action_4>.cta_content>button, .call_to_action_6>.cta_content>button, .call_to_action_7>.cta_content>button, .call_to_action_5>.cta_content>button {
        font-size: 14px;
        padding: 20px;
        padding-right: 57px;
    }
    .call_to_action_8 {
        grid-gap: 50px;
        background-size: 50%;
    }
}

/* Small devices (landscape phones, 576px and up) */

@media (min-width: 375px) and (max-width: 749px) {
    .wp-block-cta-gutenberg-cta{
        >.cta_content{
            >h2 {
                font-size: 24px;
            }
            >p {
                margin-bottom: 25px;
            }
        }
    } 
    
    .call_to_action_2, 
    .call_to_action_3, 
    .call_to_action_4, 
    .call_to_action_5, 
    .call_to_action_6, 
    .call_to_action_7, 
    .call_to_action_8, 
    .call_to_action_9, 
    .call_to_action_10, 
    .call_to_action_11,
    .call_to_action_12{
        &.alignfull{
            width: 100%;
            padding: 80px 5%;
            background-image: none !important;
        }
    }
    .call_to_action_8.alignfull {
        background-image: none !important;
    }
    .call_to_action_1, .call_to_action_2, .call_to_action_3, .call_to_action_4, .call_to_action_5, .call_to_action_6, .call_to_action_7, .call_to_action_8, .call_to_action_9, .call_to_action_10 {
        width: 80%;
        padding: 40px;
        display: block;
        background-image: none !important;
    }
    .call_to_action_1 {
        width: 70%;
        padding: 60px 40px;
        &:before, &:after{
            transform: skewX(0deg);
        }
    }
    .call_to_action_1>.cta_content {
        padding-left: 30px;
    }
    .call_to_action_3>.cta_content, .call_to_action_2>.cta_content {
        padding-right: 0;
    }
    .call_to_action_10, .call_to_action_11 {
        width: 80%;
        padding: 40px;
    }
    .call_to_action_4>.cta_content, 
    .call_to_action_10>.cta_content, 
    .call_to_action_11>.cta_content, 
    .call_to_action_10>.cta_content, 
    .call_to_action_6>.cta_content, 
    .call_to_action_7>.cta_content, 
    .call_to_action_5>.cta_content {
        display: block;
    }
    .call_to_action_4>.cta_content>h2, 
    .call_to_action_10>.cta_content>h2, 
    .call_to_action_11>.cta_content>h2,  
    .call_to_action_6>.cta_content>h2, 
    .call_to_action_7>.cta_content>h2, 
    .call_to_action_5>.cta_content>h2 {
        font-size: 20px;
        padding: 0px;
    }
    .call_to_action_4>.cta_content>p, .call_to_action_10>.cta_content>p, .call_to_action_11>.cta_content>p, .call_to_action_6>.cta_content>p, .call_to_action_7>.cta_content>p, .call_to_action_5>.cta_content>p {
        padding: 0px;
    }
    .call_to_action_4>.cta_content>button, .call_to_action_10>.cta_content>button, .call_to_action_11>.cta_content>button, .call_to_action_6>.cta_content>button, .call_to_action_7>.cta_content>button, .call_to_action_5>.cta_content>button {
        font-size: 14px;
        padding: 20px;
        padding-right: 57px;
        position: inherit;
        margin-top: 50px;
    }
    .call_to_action_8 {
        grid-gap: 50px;
        background-size: 50%;
        background-image: none !important;
    }
    .call_to_action_9>.cta_content {
        width: 100%;
    }
    .call_to_action_9>.cta_content>button {
        margin: 0;
    }
    .call_to_action_4, .call_to_action_5, .call_to_action_6, .call_to_action_7, .call_to_action_10, .call_to_action_11 {
        width: 80%;
        padding: 40px;
        > .cta_content{
            > .ctagb-btn{
                position: relative;
                top: 0;
                transform: none;
                margin-top: 25px;
            }
        }
    }

    .call_to_action_12{
        background-image: none !important;
        display: block;
    }
}

/* Extra small devices (portrait phones, less than 576px) */

@media (max-width: 575.98px) {
    .wp-block-cta-gutenberg-cta{
        >.cta_content{
            >h2 {
                font-size: 24px;
            }
            >p {
                margin-bottom: 25px;
            }
        }
    } 
    .call_to_action_2, 
    .call_to_action_3, 
    .call_to_action_4, 
    .call_to_action_5, 
    .call_to_action_6, 
    .call_to_action_7, 
    .call_to_action_8, 
    .call_to_action_9, 
    .call_to_action_10, 
    .call_to_action_11{
        &.alignfull{
            width: 100%;
            padding: 80px 5%;
            background-image: none;
        }
    }
    .call_to_action_8.alignfull {
        background-image: none;
    }
    .call_to_action_1, .call_to_action_2, .call_to_action_3, .call_to_action_4, .call_to_action_5, .call_to_action_6, .call_to_action_7, .call_to_action_8, .call_to_action_9 {
        width: 80%;
        padding: 40px;
        display: block;
        background-image: none;
    }
    .call_to_action_1 {
        width: 90%;
        padding: 30px;
    }
    .call_to_action_1>.cta_content {
        padding-left: 30px;
    }
    .call_to_action_3>.cta_content, .call_to_action_2>.cta_content {
        padding-right: 0;
    }
    .call_to_action_10, .call_to_action_11 {
        width: 80%;
        padding: 40px;
    }
    .call_to_action_4>.cta_content, .call_to_action_10>.cta_content, .call_to_action_11>.cta_content, .call_to_action_6>.cta_content, .call_to_action_7>.cta_content, .call_to_action_5>.cta_content {
        display: block;
    }
    .call_to_action_4>.cta_content>h2, .call_to_action_10>.cta_content>h2, .call_to_action_11>.cta_content>h2, .call_to_action_6>.cta_content>h2, .call_to_action_7>.cta_content>h2, .call_to_action_5>.cta_content>h2 {
        font-size: 20px;
        padding: 0px;
    }
    .call_to_action_4>.cta_content>p, .call_to_action_10>.cta_content>p, .call_to_action_11>.cta_content>p, .call_to_action_6>.cta_content>p, .call_to_action_7>.cta_content>p, .call_to_action_5>.cta_content>p {
        padding: 0px;
    }
    .call_to_action_4>.cta_content>button, .call_to_action_10>.cta_content>button, .call_to_action_11>.cta_content>button, .call_to_action_6>.cta_content>button, .call_to_action_7>.cta_content>button, .call_to_action_5>.cta_content>button {
        font-size: 14px;
        padding: 20px;
        padding-right: 57px;
        position: inherit;
        margin-top: 50px;
    }
    .call_to_action_8 {
        grid-gap: 50px;
        background-size: 50%;
        background-image: none !important;
    }
    .call_to_action_9>.cta_content {
        width: 100%;
    }
    .call_to_action_9>.cta_content>button {
        margin: 0;
    }
    .call_to_action_10, .call_to_action_11 {
        width: 80%;
        padding: 40px;
    }
}